import { createContext, useContext, useState } from "react";
import data from "../data/db.json";

const StateContext = createContext({});

export const ContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const getProducts = () => {
    setProducts(data.products);
  };

  const getCategories = async () => {
    setCategories(data.categories);
  };

  return (
    <StateContext.Provider
      value={{
        products,
        setProducts,
        getProducts,
        categories,
        getCategories,
        setCategories,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
