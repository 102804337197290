import { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useParams } from "react-router-dom";
import Product from "./Product";

export default function Solutions() {
  const { categorie } = useParams();
  const { products, getProducts, categories, getCategories } =
    useStateContext();

  useEffect(() => {
    getProducts();
    getCategories();
  }, [getCategories, getProducts]);

  const [search, setSearch] = useState("");
  const [selectedCategorie, setSelectedCategorie] = useState(categorie || "");
  const [searchProducts, setSearchProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;

  useEffect(() => {
    const filterItems = products.filter(
      (product) =>
        product.name.toLowerCase().includes(search.toLowerCase()) ||
        product.categorie.toLowerCase().includes(search.toLowerCase())
    );
    setSearchProducts(filterItems);
  }, [search, products]);

  useEffect(() => {
    const filterProducts = products.filter(
      (product) =>
        product.categorie === selectedCategorie || selectedCategorie === ""
    );
    setSearchProducts(filterProducts);
  }, [selectedCategorie, products]);

  const npage = Math.ceil(searchProducts.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = searchProducts.slice(firstIndex, lastIndex);

  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCP(n) {
    setCurrentPage(n);
  }

  return (
    <div className="container pt-4">
      <div className="solutions__top">
        <h2>Nos produits </h2>
        <form
          className="solutions__search"
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            type="text"
            placeholder="Rechercher des produits "
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setSelectedCategorie(e.target.value)}
            value={selectedCategorie}
          >
            <option value="">Tous </option>
            {categories.map((cat, i) => (
              <option value={cat.name} key={i}>
                {cat.name}
              </option>
            ))}
          </select>
        </form>
      </div>
      <div className="row row-cols-1 row-cols-md-1 row-cols-lg-4 g-3">
        {records.length === 0 && (
          <h1 className="text-center w-100">Aucun produit</h1>
        )}
        {records.map((product) => (
          <Product key={product.id} product={product} />
        ))}
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button className="page-link" onClick={prePage}>
                Précédent
              </button>
            </li>
            {numbers.map((n) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={n}
              >
                <button className="page-link" onClick={() => changeCP(n)}>
                  {n}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link" onClick={nextPage}>
                Suivant
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
