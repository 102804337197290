import { Link, useParams } from "react-router-dom";
import data from "../data/db.json";

export default function SingleProduct() {
  const { id } = useParams();
  const products = data.products;
  const product = products.find((product) => product.id.toString() === id);

  return (
    <div className="container container-fluid pt-4 pb-4">
      <div className="row align-items-center">
        <div className="col-md-6 img-side">
          <img
            src={require("../images/" + product.image)}
            alt=""
            style={{ borderRadius: "40px" }}
          />
        </div>
        <div className="col-md-6 text-side">
          <form>
            <div className="row mb-3">
              <div className="col-sm-8">
                <input className="form-control" value={product.name} readOnly />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-8">
                <input
                  className="form-control"
                  readOnly
                  value={"Marque: " + product.marque}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-8">
                <input
                  className="form-control"
                  readOnly
                  value={"Catégorie: " + product.categorie}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-8">
                <label className="form-label w-100 text-start">
                  Description
                </label>
                <div className="form-control">{product.description}</div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="offset-sm-4 col-sm-4 d-grid">
                <Link to="/contact" className="btn btn-primary">
                  nous contactez
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
