import ghs from "../images/ghs.png";
import { Link } from "react-router-dom";

export default function Header() {
  const showMenu = (toggleId, navId) => {
    const toggle = document.getElementById(toggleId),
      nav = document.getElementById(navId);
    nav.classList.toggle("show-menu");
    toggle.classList.toggle("show-icon");
  };
  return (
    <header className="header">
      <nav className="nav container">
        <div className="nav__data">
          <a href="/" className="nav__logo">
            <img src={ghs} alt="ghs" className="ghs__logo" />
          </a>

          <div
            className="nav__toggle"
            id="nav-toggle"
            onClick={() => {
              showMenu("nav-toggle", "nav-menu");
            }}
          >
            <i className="ri-menu-line nav__burger"></i>
            <i className="ri-close-line nav__close"></i>
          </div>
        </div>

        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li>
              <Link to="/" className="nav__link">
                Accueil
              </Link>
            </li>
            <li>
              <Link to="/products" className="nav__link">
                Nos produits
              </Link>
            </li>

            <li>
              <Link to="/propos" className="nav__link">
                A propos
              </Link>
            </li>

            <li>
              <Link to="/contact" className="nav__link">
                Contact
              </Link>
            </li>
            <li>
              <a href="/" className="nav__link">
                <button className="catalogue">Catalogue</button>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
