import { useState } from "react";
import emailjs from "@emailjs/browser";
import email from "../images/jmail.jpg";
import tel from "../images/phonee.png";

export default function Contact() {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    tel: "",
    user_email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID, // Replace with your EmailJS Service ID
        process.env.REACT_APP_TEMPLATE_ID, // Replace with your EmailJS Template ID
        formData,
        process.env.REACT_APP_USER_ID // Replace with your EmailJS User ID
      )
      .then((result) => {
        console.log(result.text);
        alert("Email sent successfully!");
        setFormData({
          nom: "",
          prenom: "",
          tel: "",
          user_email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to send email.");
      });
  };

  return (
    <div className="containter  productsList">
      <h1 className="text-center mt-2">Nous contacter </h1>

      <div className="col-md-8 mx-auto contact-top">
        <div className="contact-phone">
          <img src={tel} alt="phone" className="contact-img" />
          <h6>+212661217854</h6>
        </div>
        <div className="contact-email">
          <img
            src={email}
            alt="email"
            className="contact-img"
            style={{ borderRadius: "50%" }}
          />
          <h6 className="contact-title">general.med.service@gmail.com</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mx-auto rounded border p-4">
          <h2 className="text-center mb-5">Formulaire de contact </h2>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Nom </label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  name="nom"
                  placeholder="Nom"
                  value={formData.nom}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Prénom </label>
              <div className="col-sm-8">
                <input
                  className="form-control"
                  name="prenom"
                  placeholder="Prénom"
                  value={formData.prenom}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Téléphone </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="tel"
                  placeholder="Téléphone"
                  value={formData.tel}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Email* </label>
              <div className="col-sm-8">
                <input
                  type="email"
                  className="form-control"
                  name="user_email"
                  placeholder="Email"
                  value={formData.user_email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Message* </label>
              <div className="col-sm-8">
                <textarea
                  className="form-control"
                  placeholder="Entrez votre message "
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>

            <div className="row mb-3">
              <div className="offset-sm-4 col-sm-4 d-grid">
                <button type="submit" className="btn btn-primary">
                  Envoyer le message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
