import React from "react";
import image from "../images/about.jpg"; // Import your image here

const Propos = () => {
  return (
    <div className="container productsList mt-3 ">
      <h1 className="text-center">À propos de GHS</h1>
      <div className="row propos">
        <div className="col-md-6">
          <h2 className="mt-3">Notre Société</h2>
          <p>
            Notre société se spécialise dans la vente et la maintenance
            d'équipements d'imagerie médicale de haute qualité. Nous nous
            engageons à fournir aux professionnels de la santé les outils les
            plus avancés pour un diagnostic précis et efficace. Grâce à notre
            expertise et notre dévouement à l'innovation, nous offrons une gamme
            complète de produits, allant des scanners IRM aux échographes,
            conçus pour répondre aux besoins diversifiés des établissements
            médicaux. En plus de la vente, nous assurons un service de
            maintenance rigoureux et réactif, garantissant la performance et la
            longévité de vos équipements. Notre mission est de contribuer à
            améliorer les soins de santé en mettant à disposition des
            technologies fiables et de pointe, accompagnées d'un support
            technique exceptionnel.
          </p>
        </div>
        <div className="col-md-6">
          <img src={image} className="propos__img img-fluid" alt="About GHS" />
        </div>
      </div>
    </div>
  );
};

export default Propos;
