import { createBrowserRouter } from "react-router-dom";
import Contact from "./pages/Contact";
import Propos from "./pages/Propos";
import App from "./App";
import Home from "./pages/Home";
import Solutions from "./pages/Solutions";
import SingleProduct from "./pages/SingleProduct";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/propos",
        element: <Propos />,
      },
      {
        path: "/products",
        element: <Solutions />,
      },
      {
        path: encodeURI("/products/:categorie"),
        element: <Solutions />,
      },
      {
        path: "/products/product/:id",
        element: <SingleProduct />,
      },
    ],
  },
]);

export default router;
