import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-dark text-white py-4">
      <div className="container">
        <div className="row">
          {/* About */}
          <div className="col-md-4">
            <h5>A propos de nous </h5>
            <p>
              Pour toute information supplémentaire sur nos produits et
              services, n'hésitez pas à nous contacter. Notre équipe dédiée est
              à votre disposition pour répondre à vos questions et vous offrir
              le meilleur support possible. Nous nous réjouissons de collaborer
              avec vous pour améliorer la qualité des soins de santé grâce à nos
              solutions d'imagerie médicale de pointe.
            </p>
          </div>
          {/* Social Media */}
          <div className="col-md-4">
            <ul className="list-unstyled">
              <li>
                <p className="d-flex align-items-center gap-1">
                  <i className="ri-phone-fill"></i>
                  <small>Fixe: +212666557225</small>
                </p>
              </li>
              <li>
                <p className="d-flex align-items-center gap-1">
                  <i className="ri-whatsapp-fill"></i>
                  <small>+212661217854</small>
                </p>
              </li>
              <li>
                <p className="d-flex align-items-center gap-1">
                  <i className="ri-mail-fill"></i>
                  <small>general.med.service@gmail.com</small>
                </p>
              </li>
              <li>
                <p className="d-flex align-items-center gap-1">
                  <i className="ri-map-pin-2-fill"></i>
                  <small>
                    Bureau: 42 Avenue des forces auxiliaires 1er Etage Hay Rajaa
                    CASABLANCA{" "}
                  </small>
                </p>
              </li>
            </ul>
          </div>
          {/* Other Pages */}
          <div className="col-md-4">
            <h5>Autre pages</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/contact">Contact-nous </Link>
              </li>
              <li>
                <Link to="/products">produits</Link>
              </li>
              <li>
                <Link to="/propos">A propos </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <div className="col text-center">
            <p>&copy; {year}. Tous les droits sont réservés.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
