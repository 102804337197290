import { Link } from "react-router-dom";
export default function Product({ product }) {
  return (
    <div className="col-sm">
      <div className="card ">
        <img
          src={require("../images/" + product.image)}
          className="card-img-top"
          height="230"
          alt=""
        />
        <div className="card-body">
          <h4 className="card-title">{product.name}</h4>
          <h5 className="card-title">
            Marque: <b>{product.marque}</b>
          </h5>
          <p title={product.description}>
            {product.description.length > 25
              ? product.description.slice(0, 45) + "..."
              : product.description}
          </p>
          <Link
            to={"/products/product/" + product.id}
            className="btn btn-primary text-decoration-underline"
          >
            voir +
          </Link>
        </div>
      </div>
    </div>
  );
}
