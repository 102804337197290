import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import scanner from "../images/dr3.webp";
import pro from "../images/dr4.jpg";
import mscanner from "../images/mascanner.jpg";
import audition from "../images/dr6.jpg";
import blocoperation from "../images/dr5.jpg";
import { useStateContext } from "../contexts/ContextProvider";

export default function Home() {
  const categoryRefs = useRef([]);
  const { categories, getCategories } = useStateContext();

  useEffect(() => {
    getCategories();
    if (categories.length > 0) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
          }
        });
      }, options);

      categoryRefs.current.forEach((ref) => {
        if (ref) {
          observer.observe(ref);
        }
      });

      return () => observer.disconnect();
    }
  }, [categories.length, getCategories]);

  return (
    <div className="container">
      <div className="container-fluid hero-section">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 order-md-1 img-side hero-s2">
            <div
              id="referencesCarousel"
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="3000" // 3 seconds interval
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={scanner} alt="Reference 1" />
                </div>
                <div className="carousel-item">
                  <img src={audition} alt="Reference 2" />
                </div>
                <div className="carousel-item">
                  <img src={blocoperation} alt="Reference 3" />
                </div>
                <div className="carousel-item">
                  <img src={pro} alt="Reference 3" />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#referencesCarousel"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#referencesCarousel"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col-md-6 order-md-2 text-side">
            <h1>
              Bienvenue chez GHS Votre Partenaire en Matière de Soins de Santé
            </h1>
            <p>
              Chez GHS, nous nous engageons à fournir des solutions complètes
              pour répondre à vos besoins en matière de maintenance et
              d'approvisionnement en équipements médicaux de pointe. En tant que
              leader de l'industrie, nous combinons expertise technique et
              service client exceptionnel pour offrir des solutions fiables et
              efficaces à nos clients du secteur de la santé. Notre gamme de
              services comprend la maintenance préventive et corrective pour une
              variété d'équipements médicaux, allant des scanners aux
              dispositifs d'imagerie avancée. De plus, nous sommes fiers de
              proposer une large sélection de produits médicaux de haute
              qualité, couvrant toutes les catégories essentielles pour les
              établissements de santé modernes.
            </p>
            <Link to="/products" className="btn btn-primary">
              Voir nos produits
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid hero-section">
        <div className="row align-items-center">
          <h1
            className="text-center text-decoration-underline"
            style={{ marginBottom: "3rem" }}
          >
            Maintenance des matériels médicaux
          </h1>
          <div className="col-md-6 img-side">
            <img
              src={mscanner}
              alt="maintenance"
              style={{ borderRadius: "40px" }}
            />
          </div>
          <div className="col-md-6 text-side">
            <p>
              Chez GHS, nous sommes dévoués à fournir des solutions complètes
              pour répondre à vos besoins en matière de maintenance et
              d'approvisionnement en équipements médicaux de pointe.
            </p>
            <p>
              Notre équipe expérimentée s'engage à assurer le bon fonctionnement
              de vos équipements médicaux, garantissant ainsi la continuité des
              soins pour vos patients.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid category-section pb-4">
        <div className="row">
          <div className="col">
            <h1
              className="text-center text-decoration-underline"
              style={{ marginTop: "1rem", marginBottom: "3rem" }}
            >
              Nos Catégories
            </h1>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-2">
          {categories.map((cat, i) => (
            <div
              key={cat.id}
              className="col-sm category-item"
              ref={(el) => (categoryRefs.current[i] = el)}
            >
              <div className="card">
                <img
                  src={require("../images/" + cat.image)}
                  className="card-img-top"
                  height="230"
                  alt={cat.name}
                />
                <div className="card-body">
                  <Link to={"/products/" + cat.name} className="text-center h3">
                    {cat.name}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
